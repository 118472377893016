import request from './config'

export function getRoleList () {
  return request.get('/admin/role')
}

export function editRole ({ id, name }) {
  if (id) {
    return request.put(`/admin/role/${id}`, { name })
  } else {
    return request.post('/admin/role', { name })
  }
}

export function deleteRole (id) {
  return request.delete(`/admin/role/${id}`)
}

export function getRoleUser (id, params) {
  return request.get(`/admin/role/user/${id}`, { params })
}

export function setRoleUser (roleId, adminUserIds) {
  return request.post('/admin/role/user', { roleId, adminUserIds })
}

export function deleteRowUser (ids) {
  return request.delete(`/admin/role/user/${ids}`)
}

export function moveUserRole (id, roleId) {
  return request.put(`/admin/role/user/move/${id}?roleId=${roleId}`)
}

export function getRoleAuth (roleId) {
  return request.get(`/admin/role/permission/${roleId}`)
}

export function setRoleAuth (roleId, permissionIds) {
  return request.put(`/admin/role/permission/${roleId}`, { permissionIds })
}
