import request from './config'

/**
 * 新增用户排除展示字段
 */
export function adduserRelation (adminUserId, params) {
  return request.post(`/admin/fields/userRelation/${adminUserId}`, params)
}
/**
 * 查询用户排除字段
 */
export function searchuserRelation (adminUserId) {
  return request.get(`/admin/fields/userRelation/${adminUserId}`)
}
/**
 * 查询所有字段权限
 */
export function alluserRelation () {
  return request.get('/admin/fields')
}
